import { useDispatch, useSelector } from "react-redux";
import VideoCard from "../TrendingSlides/VideoCard";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  genarateDeepLink,
  getAllVideosAPI,
} from "../../redux/slices/app/appService";
import { ReactComponent as BackIcon } from "../../images/left-arrow.svg";
import { ProductDetailsStyled } from "../VideoPdp/ProductDeatilsStyled.styled";
import { ReactComponent as ArrowRight } from "../../images/arrow-right-s-line.svg";
import ContentLoaderSection from "../../components/Common/ContentLoaderSection/ContentLoaderSection";

const BrandsCategory = () => {
  const { getAllVideos } = useSelector((state: any) => state.app);

  const [brandVideosArray, setBrandVideoArray]: any = useState([]);
  const [offers, setOffers] = useState([]);
  const [deepLink, setDeepLink] = useState("");
  const [loading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const { id }: any = useParams();

  useEffect(() => {
    handleGetAllVideos();
  }, []);

  useEffect(() => {
    let brandVideos = getAllVideos.filter((item: any) => item?.brand.id === id);
    setBrandVideoArray(brandVideos);
  }, [getAllVideos, id]);

  useEffect(() => {
    let offersArray = brandVideosArray
      .flatMap((item: any) => item?.banners)
      .filter((item: any) => item?.title !== "");
    setOffers(offersArray);
  }, [brandVideosArray]);

  const handleGetAllVideos = async () => {
    setIsLoading(true);
    await dispatch(getAllVideosAPI({}));
    setIsLoading(false);
  };
  useEffect(() => {
    handleGenrateDeepLink(brandVideosArray[0]?.brand?.merchantUrl);
  }, [brandVideosArray]);

  const handleGenrateDeepLink = async (url: any) => {
    let json = {
      merchantUrl: url,
      placement_id: "",
      click_id: "",
      campaign_id: 5029,
      isRedClubAI: true,
      new_campaign_id: "",
      apiKey: "6wJK70SBvx04RQOiHG5mKwJ6Tyz9D2fxulct6jIaWcQCUgIYnI",
    };
    let res: any = await dispatch(genarateDeepLink(json));

    setDeepLink(res.payload?.deepLinkInfo?.link);
  };

  return (
    <BrandsCategoryStyled>
      {loading ? (
        <ContentLoaderSection fromPage="pdp" />
      ) : (
        <>
          <div
            className="back-sec"
            onClick={() => {
              history.goBack();
            }}
          >
            <BackIcon />
            Back
          </div>
          <h2 className="brand-catogery-heading">
            {brandVideosArray?.length > 0
              ? brandVideosArray[0]?.brand.name
              : null}
          </h2>
          <div className="video-category-cards">
            {brandVideosArray?.map((video: any, index: number) => (
              <VideoCard
                key={index}
                video={video}
                fromPage={"brands"}
                deepLink={deepLink}
              />
            ))}
          </div>
          <div className="mb-5">
            <h3 className="offers-brand-heading">Offers</h3>
            <ProductDetailsStyled>
              <div className="offers-section brands-offers-sec">
                {offers.map((banner: any, index: number) => (
                  <div key={index} className="offer-card">
                    {/* <img src={banner.brand.logo} alt="offer" /> */}
                    <div className="offer-details-section">
                      <div className="offer-details">
                        <h4>{banner.title}</h4>
                        <p>{banner.description}</p>
                      </div>
                      <div
                        className="shop-now-button"
                        onClick={() => {
                          window.open(deepLink);
                        }}
                      >
                        Shop now
                        <ArrowRight />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </ProductDetailsStyled>
          </div>
        </>
      )}
    </BrandsCategoryStyled>
  );
};
export default BrandsCategory;

export const BrandsCategoryStyled = styled.div`
  .video-category-cards {
    display: grid;
    column-gap: 1.25%;
    row-gap: 24px;
    grid-template-columns: 19% 19% 19% 19% 19%;
    margin-bottom: 72px;
  }
  .back-sec {
    color: var(--Black, #000);
    font-family: "AvenirNextMedium";
    font-size: 20px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    display: flex;
    align-items: center;
    margin-top: 32px;
    cursor: pointer;
    p {
      margin-bottom: 0px;
    }
  }
  .brand-catogery-heading {
    color: var(--secondary-colour-300-main, #0b0b0b);
    font-family: "AvenirNextSemiBold";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px; /* 115% */
    margin-top: 40px;
    margin-bottom: 32px;
  }
  .offers-brand-heading {
    color: var(--secondary-colour-300-main, #0b0b0b);
    font-family: "AvenirNextSemiBold";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px; /* 115% */
    margin-bottom: 32px;
  }
  @media (max-width: 575.98px) {
    .video-category-cards {
      column-gap: 4%;
      grid-template-columns: 48% 48%;
      row-gap: 16px;
    }
    .video-logo img {
      height: 16px;
    }
  }
`;
