import React, { useEffect } from "react";
import { ensureProtocol, getUrlSlug } from "../../library/Requests/helpers";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllVideosAPI } from "../../redux/slices/app/appService";
import ContentLoaderSection from "../../components/Common/ContentLoaderSection/ContentLoaderSection";

const BrandVideo = () => {
  const dispatch = useDispatch();
  const { getAllVideos = [] } = useSelector((state: any) => state.app);
  const { brandName } = useParams<{ brandName: string }>();
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const extractedUrl = queryParams.get("url");

    if (extractedUrl) {
      window.location.href = ensureProtocol(extractedUrl);
    }
    dispatch(getAllVideosAPI({}));
  }, []);

  useEffect(() => {
    console.log("getAllVideos", getAllVideos);
    let redirectTimeout: any;
    if (getAllVideos.length > 1) {
      let brandVideos = getAllVideos.filter(
        (item: any) =>
          getUrlSlug(item?.VideoTitle) === getUrlSlug(brandName) ||
          getUrlSlug(item?.brand.name) === getUrlSlug(brandName) ||
          getUrlSlug(item?.brand.merchantUrl) === getUrlSlug(brandName) ||
          getUrlSlug(item?.CreatorDetails?.Name) === getUrlSlug(brandName)
      );
      if (brandVideos.length > 0) {
        if (brandVideos.length === 1) {
          const formattedName = getUrlSlug(brandVideos[0].CreatorDetails?.Name); // Replace spaces with "-"
          const formattedTitle = getUrlSlug(brandVideos[0].VideoTitle); // Replace spaces with "-"
          redirectTimeout = setTimeout(() => {
            window.location.href = `/${formattedName}/${formattedTitle}`;
            // window.location.href = `/video/${formattedName}/${formattedTitle}/${brandVideos[0]._id}`;
          }, 3000);
        } else {
          const brandName = getUrlSlug(brandVideos[0].brand?.name);
          redirectTimeout = setTimeout(() => {
            window.location.href = `/brands-category/${brandName}/${brandVideos[0].brand?.id}`;
            // window.location.href = `/video/${formattedName}/${formattedTitle}/${brandVideos[0]._id}`;
          }, 3000);
        }
      } else {
        redirectTimeout = setTimeout(() => {
          window.location.href = `/`;
        }, 3000);
      }
    } else {
      redirectTimeout = setTimeout(() => {
        window.location.href = `/`;
      }, 5000);
    }

    return () => {
      if (redirectTimeout) {
        clearTimeout(redirectTimeout);
      }
    };
  }, [getAllVideos, brandName]);

  return (
    <div className="my-4">
      <ContentLoaderSection fromPage="pdp" />
    </div>
  );
};

export default BrandVideo;
