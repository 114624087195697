import { useHistory } from "react-router-dom";
import styled from "styled-components";
import EyeIcon from "../../images/Reel-logos/eye-icon.svg";
import { getBrandLogoUrl } from "../../Utills/utils";
import VideoPlayer from "../../components/VideoPlayer";
import { useEffect, useRef } from "react";
import { ReactComponent as FaceBook } from "../../images/facebook-circle-fill.svg";
import { ReactComponent as Youtube } from "../../images/youtube-fill.svg";
import { ReactComponent as Instagram } from "../../images/instagram-fill.svg";
import { ReactComponent as TickTok } from "../../images/tiktok-fill.svg";
import { ReactComponent as SnapChat } from "../../images/snapchat-fill.svg";
import { ReactComponent as X } from "../../images/twitter-x-fill.svg";
import { ReactComponent as Right } from "../../images/arrow-right-s-line.svg";
import { Button } from "antd";
import { getUrlSlug } from "../../library/Requests/helpers";

const VideoCard = (props: any) => {
  const { video, profileVideo, livestream, fromPage, deepLink } = props;
  const history = useHistory();

  const navigatetoVideo = (name: any, title: any) => {
    const formattedName = getUrlSlug(name); // Replace spaces with "-"
    const formattedTitle = getUrlSlug(title); // Replace spaces with "-"
    history.push(`/${formattedName}/${formattedTitle}`);
    // history.push(`/video/${formattedName}/${formattedTitle}/${video?._id}`);
  };

  const videoRef: any = useRef();

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current
        .play()
        .catch((error: any) => console.error("Autoplay failed:", error));
    }
  }, []);

  const handleSliptUserName = (name: any) => {
    if (name) {
      let userName = name?.split("/");
      let socailUserName = userName[userName.length - 1];
      console.log(socailUserName);
      return socailUserName;
    }
  };

  return (
    <VideoCardStyled>
      <div
        className="video-card"
        onClick={() =>
          navigatetoVideo(video?.CreatorDetails?.Name, video?.VideoTitle)
        }
      >
        <VideoPlayer
          className="card-video"
          playOnHover
          mouseOver={true}
          url={video?.CDNUrl}
          thumb={video?.ThumbnailImageUrl}
        />

        {profileVideo && (
          <div className="eye-icon-sec">
            {livestream && <div className="timer-sec">01:20:10</div>}
            <div className="icon-sec">
              <img src={EyeIcon} alt="eye" />
              <p>1M</p>
            </div>
          </div>
        )}
      </div>
      <div className="video-logo">
        <img
          src={getBrandLogoUrl(video?.brand?.id, video?.brand.logo)}
          alt="logo"
        />
      </div>
      {fromPage === "brands" && (
        <div className="video-creator-details">
          <h3>{video?.VideoTitle}</h3>
          <div className="socail-login-profile">
            {(video.CreatorDetails?.socialAccounts?.facebook ||
              video.CreatorDetails?.socialAccounts?.tiktok ||
              video.CreatorDetails?.socialAccounts?.instagram ||
              video.CreatorDetails?.socialAccounts?.youtube ||
              video.CreatorDetails?.socialAccounts?.snapchat ||
              video.CreatorDetails?.socialAccounts?.x) && (
              <p>Connect With {video?.CreatorDetails?.Name} on</p>
            )}
            <div>
              {video.CreatorDetails?.socialAccounts?.facebook && (
                <div>
                  <FaceBook
                    onClick={() => {
                      window.open(
                        `https://www.facebook.com/${handleSliptUserName(
                          video.CreatorDetails?.socialAccounts?.facebook
                        )}`
                      );
                    }}
                  />
                </div>
              )}

              {video.CreatorDetails?.socialAccounts?.tiktok && (
                <div>
                  <TickTok
                    onClick={(e: any) => {
                      e.stopPropagation();
                      window.open(
                        `https://www.tiktok.com/${handleSliptUserName(
                          video.CreatorDetails?.socialAccounts?.tiktok
                        )}`
                      );
                    }}
                  />
                </div>
              )}
              {video.CreatorDetails?.socialAccounts?.instagram && (
                <div>
                  <Instagram
                    onClick={(e: any) => {
                      e.stopPropagation();
                      window.open(
                        `https://www.instagram.com/${handleSliptUserName(
                          video.CreatorDetails?.socialAccounts?.instagram
                        )}`
                      );
                    }}
                  />
                </div>
              )}
              {video.CreatorDetails?.socialAccounts?.youtube && (
                <div>
                  <Youtube
                    onClick={(e: any) => {
                      e.stopPropagation();
                      window.open(
                        `https://www.youtube.com/${handleSliptUserName(
                          video.CreatorDetails?.socialAccounts?.youtube
                        )}`
                      );
                    }}
                  />
                </div>
              )}
              {video.CreatorDetails?.socialAccounts?.snapchat && (
                <div>
                  <SnapChat
                    onClick={(e: any) => {
                      e.stopPropagation();
                      window.open(
                        `https://www.snapchat.com/${handleSliptUserName(
                          video.CreatorDetails?.socialAccounts?.snapchat
                        )}`
                      );
                    }}
                  />
                </div>
              )}
              {video.CreatorDetails?.socialAccounts?.x && (
                <div>
                  <X
                    onClick={(e: any) => {
                      e.stopPropagation();
                      window.open(
                        `https://x.com/${handleSliptUserName(
                          video.CreatorDetails?.socialAccounts?.x
                        )}`
                      );
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <Button
            onClick={() => {
              window.open(deepLink);
            }}
          >
            Shop Now <Right />
          </Button>
        </div>
      )}
    </VideoCardStyled>
  );
};

export default VideoCard;

export const VideoCardStyled = styled.div`
  .video-card {
    width: 100%;
    position: relative;
    cursor: pointer;
    aspect-ratio: 9/16;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .profile-des {
      position: absolute;
      bottom: 0px;
      width: 100%;
      display: flex;
      gap: 10px;
      color: white;
      background-color: rgba(0, 0, 0, 0.3);
      padding: 5px 10px;
      border-radius: 5px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        object-fit: cover;
      }

      .video-name {
        color: #fff;
        h3 {
          font-family: "AvenirNextRegular";
          font-size: 16px;
          font-weight: 500;
          line-height: var(--lineheight20);
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          margin: 0;
        }
        span {
          font-family: "AvenirNextLight";
          font-size: 12px;
          font-weight: 400;
          line-height: var(--lineheight20);
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
      }
    }
  }
  .eye-icon-sec {
    position: absolute;
    top: 16px;
    display: flex;
    align-items: center;
    right: 16px;
    gap: 8px;
    width: 85%;
    justify-content: space-between;
    .timer-sec {
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: 0.002em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #fff;
      backdrop-filter: blur(52px);
      background: #21212129;
      border-radius: 40px;
      padding: 5px 12px;
    }
    .icon-sec {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    p {
      margin-bottom: 0px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #fff;
    }
  }
  .video-logo img {
    width: 100%;
    height: 24px;
    object-fit: contain;
  }
  .video-creator-details {
    margin-top: 8px;

    h3 {
      color: #000;
      font-family: "AvenirNextRegular";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.54px;
      margin-bottom: 12px;
    }
    .socail-login-profile {
      p {
        color: var(--Shade-100, #000);
        font-family: "AvenirNextRegular";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 8px;
      }
      div {
        display: flex;
        gap: 7px;
        align-items: center;
        cursor: pointer;
        div {
          padding: 6px;
          border-radius: 6px;
          background: #faf1ff;
        }
      }
    }
    button {
      border-radius: 10px;
      background: var(--Videoshops-Purple, #762da0);
      color: var(
        --COSMETICS-ON-OFFERS-COLORS-White,
        var(--color-white-solid, #fff)
      );
      font-family: "AvenirNextMedium";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.024px;
      text-transform: uppercase;
      width: 100%;
      margin-top: 24px;
      padding: 8px 24px;
    }
  }
  @media (max-width: 575.98px) {
    .video-card .profile-des img {
      width: 24px;
      height: 24px;
    }
    .video-card .profile-des .video-name {
      display: flex;
      flex-direction: column;
    }
    .video-card .profile-des .video-name h3 {
      font-size: 11px;
    }
    .video-card .profile-des .video-name span {
      font-size: 8px;
    }
  }
`;
